var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-button', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.modal-customer-service-fee",
      modifiers: {
        "modal-customer-service-fee": true
      }
    }],
    staticClass: "rounded-pill py-75 bg-white",
    attrs: {
      "size": _vm.sizeButton,
      "variant": _vm.variantButton
    }
  }, [_c('HStack', {
    attrs: {
      "center": ""
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "EditIcon",
      "size": "14"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('flight.setServiceFee')) + " ")], 1)], 1), _c('ModalCustomServiceFee')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }