<template lang="">
  <div>
    <b-button
      v-b-modal.modal-customer-service-fee
      :size="sizeButton"
      :variant="variantButton"
      class="rounded-pill py-75 bg-white"
    >
      <HStack center>
        <feather-icon
          icon="EditIcon"
          size="14"
        />
        {{ $t('flight.setServiceFee') }}
      </HStack>
    </b-button>

    <ModalCustomServiceFee />
  </div>
</template>
<script>
import { BButton } from 'bootstrap-vue'

export default {
  components: {
    BButton,
    ModalCustomServiceFee: () => import('@flightv2/result/components/ModalCustomServiceFee.vue'),
  },
  props: {
    variantButton: {
      type: String, default: 'flat-warning',
    },
    sizeButton: {
      type: String, default: 'md',
    },
  },
}
</script>
<style lang="">

</style>
